import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Header as AntHeader } from 'antd/lib/layout/layout';
import messages from './messages';
import { useIntl } from 'react-intl';

const Header = () => {
  const intl = useIntl();

  return (
    <AntHeader style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <Row justify="left" align="middle" style={{ height: '100%' }}>
        <Col md={12} sm={24} xs={24} lg={20} justify="left">
          <Typography.Title
            style={{ color: 'white', marginBottom: 0 }}
            level={3}
          >
            {intl.formatMessage(messages.globalHeader)}
          </Typography.Title>
        </Col>
      </Row>
    </AntHeader>
  );
};

export default Header;
